<template>
  <div class="col-10 mt-3">
    <table class="courses-table">
      <tr v-for="(row, rowIndex) in chunkedCourses" :key="rowIndex">
        <td v-for="course in row" :key="course.id" bgcolor="black">
          <div class="tooltip">
            <span class="tooltiptext">{{ course.description }}</span>
            <a :href="course.landing_page" target="_blank">
              <img
                :src="course.thumbnail_on_s3"
                width="100"
                height="100"
                :alt="course.description"
              />
            </a>
          </div>
        </td>
      </tr>
    </table>
    <p style="text-align: center">&copy; electrical training ALLIANCE 2024</p>
  </div>
</template>

<script>
export default {
  name: "Courses",
  data() {
    return {
      courses: [
        {
          id: "AC",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/AC.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/AC.jpg",
          landing_page: "https://platform.sorcero.io/eta-ac/training",
          description: "Applied Codeology, Navigating the 2023 NEC",
        },
        {
          id: "CD",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/CD.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/CD.jpg",
          landing_page: "https://platform.sorcero.io/eta-cd/training",
          description: "Construction Drawings",
        },
        {
          id: "CSF1",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/CSF1.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/CSF1.jpg",
          landing_page: "https://platform.sorcero.io/eta-csf1/training",
          description: "Conduit System Fabrication 1",
        },
        {
          id: "CSF2",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/CSF2.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/CSF2.jpg",
          landing_page: "https://platform.sorcero.io/csf2/training",
          description: "Conduit System Fabrication 2",
        },
        {
          id: "CSP1",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/CSP1.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/CSP1.jpg",
          landing_page: "https://platform.sorcero.io/eta-csp1/training",
          description: "Code, Standards, and Practices 1",
        },
        {
          id: "CSP2",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/CSP2.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/CSP2.jpg",
          landing_page: "https://platform.sorcero.io/eta-csp2/training",
          description: "Code, Standards, and Practices 2",
        },
        {
          id: "EJI",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/EJI.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/EJI.jpg",
          landing_page: "https://platform.sorcero.io/eta-eji/training",
          description: "Electrical Job Information 1",
        },
        {
          id: "ET1",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/ET1.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/ET1.jpg",
          landing_page: "https://platform.sorcero.io/et1/training",
          description: "Electrical Theory 1: Direct Current",
        },
        {
          id: "FAS",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/FAS.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/FAS.jpg",
          landing_page: "https://platform.sorcero.io/eta-fas/training",
          description: "Fire Alarm Systems",
        },
        {
          id: "FCC",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/FCC.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/FCC.jpg",
          landing_page: "https://platform.sorcero.io/fcc/training",
          description: "Fundamental Code Calculations",
        },
        {
          id: "HP",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/HP.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/HP.jpg",
          landing_page: "https://platform.sorcero.io/eta-hp/training",
          description: "Harassment Prevention",
        },
        {
          id: "ITA",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/ITA.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/ITA.jpg",
          landing_page: "https://platform.sorcero.io/eta-ita/training",
          description: "Introduction to Apprenticeship",
        },
        {
          id: "RC",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/Reading-Comprehension.jpg",
          thumbnail_on_s3:
            "https://platform.sorcero.io/assets/images/Reading-Comprehension.jpg",
          landing_page:
            "https://platform.sorcero.io/eta-reading-comprehension/training",
          description: "Reading Comprehension",
        },
        {
          id: "REI",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/REI.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/REI.jpg",
          landing_page: "https://platform.sorcero.io/eta-rei/training",
          description: "Rigging for the Electrical Industry",
        },
        {
          id: "TEI",
          thumbnail_source:
            "https://gitlab.com/sorcero/app-services/sli-frontend/-/blob/master/public/assets/images/TEI.jpg",
          thumbnail_on_s3: "https://platform.sorcero.io/assets/images/TEI.jpg",
          landing_page: "https://platform.sorcero.io/eta-tei/training",
          description: "Torque for the Electrical Industry",
        },
      ],
    };
  },
  computed: {
    chunkedCourses() {
      const chunks = [];
      const chunkSize = 5; // 5 items per row

      for (let i = 0; i < this.courses.length; i += chunkSize) {
        chunks.push(this.courses.slice(i, i + chunkSize));
      }

      return chunks;
    },
  },
};
</script>

<style scoped>
.courses-table {
  margin: 0 auto;
}

td {
  padding: 8px;
}

.tooltip {
  display: inline-block;
  opacity: 1;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;

  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  max-width: 200px;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;

  opacity: 0;
  transition: opacity 0.3s;

  z-index: 10;
  margin-bottom: 5px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
</style>
