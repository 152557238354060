import { render, staticRenderFns } from "./CreateQAPair.vue?vue&type=template&id=59323cd5&scoped=true"
import script from "./CreateQAPair.vue?vue&type=script&lang=js"
export * from "./CreateQAPair.vue?vue&type=script&lang=js"
import style0 from "./CreateQAPair.vue?vue&type=style&index=0&id=59323cd5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59323cd5",
  null
  
)

export default component.exports