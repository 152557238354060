import { render, staticRenderFns } from "./QuestionDetail.vue?vue&type=template&id=4bfa5168&scoped=true"
import script from "./QuestionDetail.vue?vue&type=script&lang=js"
export * from "./QuestionDetail.vue?vue&type=script&lang=js"
import style0 from "./QuestionDetail.vue?vue&type=style&index=0&id=4bfa5168&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bfa5168",
  null
  
)

export default component.exports